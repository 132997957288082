import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import MissionPage from './Pages/MissionPage/MissionPage'
import InprogressPage from './Pages/InprogressPage/InprogressPage'
import Contact from './Pages/Contacts/Contacts'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/mission" element={<MissionPage />} />
          <Route path="/inprogress" element={<InprogressPage />} />
          <Route path="/contacts" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
